
.loader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ion-spinner {
    margin-right: 10px;
  }
}
